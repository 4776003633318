import Web3 from "web3";

const getWeb3 = async () => {
      let web3 = window.web3;

      if (window.ethereum) {
        let ethereum = window.ethereum;
        web3 = new Web3(ethereum);
        try {
          await ethereum.enable()
          console.log("Injected web3 detected. Ethereum account access granted.");
          return web3;
        } catch (error) {
          console.log("Injected web3 detected. User deined account access...");
          return error;
        }
      } else if (typeof web3 !== "undefined") {
        // Use Mist/MetaMask's provider.
        web3 = new Web3(web3.currentProvider);
        console.log("Injected web3 detected.");
        return web3;
      } else {
        // Fallback to localhost if no web3 injection. We've configured this to
        // use the development console's port by default.
        const provider = new Web3.providers.HttpProvider(
          "http://127.0.0.1:9545"
        );
        web3 = new Web3(provider);
        console.log("No web3 instance injected, using Local web3.");
        return web3;
      }
}

export default getWeb3;

